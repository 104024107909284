import virtualTerminalStore from "../store/VirtualTerminalStore";

export class GooglePay {
  urlApi;
  paypoint;
  payabliConfig;
  googlePayRequestPayload;
  paymentRequest;
  domain;

  static SUPPORTED_BUTTON_TYPES = [
    "book",
    "buy",
    "checkout",
    "donate",
    "order",
    "pay",
    "plain",
    "long",
    "short",
  ];

  constructor({ config, paypoint, domain }) {
    const { expressCheckout = {} } = config;
    this.urlApi = process.env.REACT_APP_URL_API;
    this.payabliConfig = config;
    this.domain = domain;
    this.paypoint = paypoint;

    this.baseRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
    };

    const tokenizationSpecification = {
      type: "PAYMENT_GATEWAY",
      parameters: {
        gateway: "payabli",
        gatewayMerchantId: this.payabliConfig.entryPoint,
      },
    };

    const allowedCardNetworks =
      (expressCheckout.supportedNetworks || []).map((v) => v.toUpperCase()) ||
      [];

    const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

    this.baseCardPaymentMethod = {
      type: "CARD",
      parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks,
        billingAddressRequired: true,
        billingAddressParameters: {
          format: "FULL",
        },
      },
    };

    this.cardPaymentMethod = {
      ...this.baseCardPaymentMethod,
      tokenizationSpecification,
    };

    this.isReadyToPayRequest = {
      ...this.baseRequest,
      allowedPaymentMethods: [this.baseCardPaymentMethod],
    };
  }

  encodeBase64(str) {
    try {
      return btoa(unescape(encodeURIComponent(str)));
    } catch (error) {
      this.sendError(error, "Error encoding base64");
      return null;
    }
  }

  sendError(error, context = "Google Pay Error") {
    console.error(`${context}:`, error);
    window.parent.postMessage(
      {
        event: `callback-payabli-function-expresscheckout-error${this.payabliConfig.randomId}`,
        data: {
          error,
          paymentMethod: "google_pay",
        },
      },
      "*"
    );
  }

  async onPaymentAuthorized(paymentData) {
    try {
      const {
        expressCheckout = {},
        entryPoint,
        customerData = {},
      } = this.payabliConfig;
      const totalAmount =
        Number(expressCheckout.amount) + Number(expressCheckout.fee);
      const walletToken = this.encodeBase64(JSON.stringify(paymentData));

      const payload = {
        entryPoint,
        paymentMethod: {
          method: "wallet",
          walletType: "google_pay",
          walletToken,
          cardHolder: paymentData?.paymentMethodData?.info?.billingAddress?.name,
        },
        paymentDetails: {
          totalAmount,
          serviceFee: Number(expressCheckout.fee).toFixed(2),
        },
        source: "express_checkout_google_pay",
        customerData: {
          firstName: customerData?.firstName,
          lastName: customerData?.lastName,
          customerNumber: customerData?.customerNumber,
          billingEmail: customerData?.billingEmail,
          billingAddress1: paymentData?.paymentMethodData?.info?.billingAddress?.address1,
          billingAddress2: paymentData?.paymentMethodData?.info?.billingAddress?.address2,
          billingCity: paymentData?.paymentMethodData?.info?.billingAddress?.locality,
          billingState: paymentData?.paymentMethodData?.info?.billingAddress?.administrativeArea,
          billingZip: paymentData?.paymentMethodData?.info?.billingAddress?.postalCode,
          billingCountry: paymentData?.paymentMethodData?.info?.billingAddress?.countryCode,
        },
      };

      const response = await fetch(
        `${this.urlApi}MoneyIn/getpaid?async=false`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            requestToken: virtualTerminalStore.token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Payment authorization failed");
      }

      const data = await response.json();

      if (data.pageIdentifier) {
        virtualTerminalStore.setToken(data.pageIdentifier);
      }

      if (data.responseText !== "Success") {
        throw new Error(
          `${data?.responseText}: ${data?.responseData?.resultText}`
        );
      }

      const eventBase = `callback-payabli-function-expresscheckout-success${this.payabliConfig.randomId}`;
      window.parent.postMessage(
        { event: eventBase, data: { data, paymentMethod: "google_pay" } },
        "*"
      );
      return { transactionState: "SUCCESS" };
    } catch (error) {
      window.parent.postMessage(
        {
          event: `callback-payabli-function-expresscheckout-error${this.payabliConfig.randomId}`,
          data: {
            error,
            paymentMethod: "google_pay",
          },
        },
        "*"
      );
      return {
        transactionState: "ERROR",
        error: {
          reason: "PAYMENT_DATA_INVALID",
          message: error.message,
          intent: "PAYMENT_AUTHORIZATION",
        },
      };
    }
  }

  async googlePayInit() {
    try {
      if (!window.google || !window.google.payments) {
        throw new Error("Google Pay SDK not loaded");
      }

      const isProduction = process.env.REACT_APP_GOOGLE_PAY_ENVIRONMENT?.toLowerCase() === 'production';
      const environment = isProduction ? 'PRODUCTION' : 'TEST';
      
      // eslint-disable-next-line no-undef
      const paymentsClient = new google.payments.api.PaymentsClient({
        environment: environment,
        paymentDataCallbacks: {
          onPaymentAuthorized: (paymentData) =>
            this.onPaymentAuthorized(paymentData),
        },
      });

      this.paymentsClient = paymentsClient;

      if (!this.isReadyToPayRequest) {
        throw new Error("isReadyToPayRequest is missing");
      }
      
      const response = await paymentsClient.isReadyToPay(
        this.isReadyToPayRequest
      );
      if (!response.result) {
        throw new Error("Google Pay is not available for this user");
      }

      return { ok: true };
    } catch (error) {
      console.error("Google Pay initialization failed:", error);
      this.sendError(error, "Google Pay initialization failed");
      throw error;
    }
  }

  createButton() {
    const { expressCheckout = {} } = this.payabliConfig;
    const { googlePay = {}, appearance = {} } = expressCheckout;
    const paymentDataRequest = this.getPaymentDataRequest({
      fee: expressCheckout.fee,
      amount: expressCheckout.amount,
      currency: expressCheckout.currency,
      entryPoint: this.payabliConfig.entryPoint,
      dbaName: this.paypoint?.Paypoint?.DbaName,
    });

    const buttonType = GooglePay.SUPPORTED_BUTTON_TYPES.includes(
      googlePay?.buttonType
    )
      ? googlePay?.buttonType
      : "pay";

    const button = this.paymentsClient.createButton({
      onClick: async () => {
        try {
          await this.paymentsClient.loadPaymentData(paymentDataRequest);
        } catch (error) {
          console.error("Google Pay Error:", error);

          const eventType =
            !error || error.statusCode === "CANCELED"
              ? `callback-payabli-function-expresscheckout-cancel${this.payabliConfig.randomId}`
              : `callback-payabli-function-expresscheckout-error${this.payabliConfig.randomId}`;

          window.parent.postMessage(
            {
              event: eventType,
              data: { error, paymentMethod: "google_pay" },
            },
            "*"
          );
        }
      },
      allowedPaymentMethods: [],
      buttonLocale: googlePay?.language || "en",
      buttonColor: googlePay?.buttonStyle || "black",
      buttonType,
      buttonSizeMode: "fill",
      buttonRadius: appearance?.buttonBorderRadius || 4,
    });

    const buttonContainer = document.getElementById("google-pay-button");
    if (!buttonContainer) {
      throw new Error("Google Pay button container not found");
    }
    buttonContainer.appendChild(button);
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      if (document.getElementById("google-pay-script")) {
        if (window.google && window.google.payments) {
          console.info("Google Pay SDK already loaded");
          resolve({ ok: true });
        } else {
          console.warn(
            "Google Pay script found but SDK not ready. Retrying..."
          );
          setTimeout(() => this.loadScript().then(resolve).catch(reject), 500);
        }
        return;
      }

      const script = document.createElement("script");
      script.id = "google-pay-script";
      script.src = "https://pay.google.com/gp/p/js/pay.js";
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        if (!window.google || !window.google.payments) {
          console.error("Google Pay SDK failed to initialize properly");
          reject(new Error("Google Pay SDK not available after script load"));
          return;
        }

        console.info("Google Pay SDK loaded successfully");

        this.googlePayInit()
          .then(resolve)
          .catch((error) => {
            console.error("Google Pay initialization failed:", error);
            reject(error);
          });
      };

      script.onerror = (error) => {
        console.error("Failed to load Google Pay script:", error);
        reject(error);
      };
    });
  }

  getPaymentDataRequest({
    fee = 0,
    amount = 0,
    currency = "USD",
    entryPoint,
    dbaName,
  }) {
    const totalAmount = (Number(amount) + Number(fee)).toFixed(2);
    return {
      ...this.baseRequest,
      allowedPaymentMethods: [this.cardPaymentMethod],
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPriceLabel: "Total",
        displayItems: [
          {
            label: "Subtotal",
            type: "SUBTOTAL",
            price: Number(amount).toFixed(2),
          },
          {
            label: "Service Fee",
            type: "LINE_ITEM",
            price: Number(fee).toFixed(2),
          },
        ],
        totalPrice: totalAmount,
        currencyCode: currency,
        countryCode: "US",
      },
      merchantInfo: {
        merchantName: dbaName || "Unknown Merchant",
        merchantId: process.env.REACT_APP_GOOGLE_MERCHANT_ID,
        merchantOrigin: this.domain,
      },
      callbackIntents: ["PAYMENT_AUTHORIZATION"],
    };
  }
}
